footer {
  position: absolute;
  bottom: 6%;
  left: 50%;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateX(-50%);
  z-index: 1000;
}

footer a:hover * {
  fill: white
}