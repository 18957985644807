@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@700&display=swap');

:root {
  --background: #000000;
  --counter-up: #000000;
  --counter-down: #000000;
  --counter-value: #fff;
  --counter-type: #000000;

  --turn-easing: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --turn-duration: 0.6s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 700;
}
