.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: '#000000';
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  z-index: 1000;
}

.main__text {
  margin-bottom: 40px;
  color: black;
  text-align: center;
  font-size: 20px;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.logo {
  margin-top: 2rem;
  width: 100%;
}

@media only screen and (min-width: 420px) {
  .main {
    margin-top: 100px;
  }
  .logo {
    width: 60%;
  }
}
