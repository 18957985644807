.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  margin-right: 10px;
}

.counter:last-child {
  margin-right: 0;
}

.counter__card {
  position: relative;
  perspective-origin: 50% 50%;
  perspective: 300px;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: var(--counter-down);
  text-align: center;
}

.counter__circles {
  position: absolute;
  top: 50%;
  left: -1%;
  width: 102%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 0;
  overflow: hidden;
}

.circle {
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  background-color: #000;
}

.circle--left {
  transform: translateX(-50%);
}

.circle--right {
  transform: translateX(50%);
}

.counter__value {
  color: var(--counter-value);
}

.counter__type {
  font-size: 7px;
  letter-spacing: 4px;
  color: var(--counter-type);
  text-align: center;
  text-transform: uppercase;
  transform: translateX(2px);
}

/* Flip Animation */

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.upperCard {
  align-items: flex-end;
  background-color: var(--counter-up);
  border-bottom: 0.25px solid transparent;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.25px solid transparent;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.upperCard span,
.lowerCard span,
.flipCard span {
  font-size: 24px;
  color: var(--counter-value);
}

.upperCard span {
  transform: translateY(50%);
}

.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;
}

.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color: var(--counter-down);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 0.25px solid transparent;
  border-top: 0.25px solid transparent;
}

.flipCard.unfold span {
  transform: translateY(-50%);
}

.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color: var(--counter-up);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 0.25px solid transparent;
  border-bottom: 0.25px solid transparent;
}

.flipCard.fold span {
  transform: translateY(50%);
}

.fold {
  animation: fold var(--turn-duration) var(--turn-easing) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  animation: unfold var(--turn-duration) var(--turn-easing) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@keyframes fold {
  from {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes unfold {
  from {
    transform: rotateX(180deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

@media only screen and (min-width: 380px) {
  .counter {
    width: 70px;
    margin-right: 16px;
  }

  .counter__card {
    height: 70px;
  }

  .upperCard span,
  .lowerCard span,
  .flipCard span {
    font-size: 26px;
  }
}

@media only screen and (min-width: 600px) {
  .counter {
    width: 80px;
    margin-right: 18px;
  }

  .counter__card {
    height: 80px;
  }

  .upperCard span,
  .lowerCard span,
  .flipCard span {
    font-size: 28px;
  }

  .counter__type {
    font-size: 8px;
  }
}

@media only screen and (min-width: 800px) {
  .counter {
    width: 100px;
    margin-right: 20px;
  }

  .counter__card {
    height: 100px;
  }

  .upperCard span,
  .lowerCard span,
  .flipCard span {
    font-size: 40px;
  }

  .counter__type {
    font-size: 10px;
  }
}
